<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="alert alert-success" v-if="connected">
          <p>You are connected to xero</p>
        </div>
        <div class="alert alert-warning" v-else>
          <p>You are not connected to xero</p>
        </div>

        <button @click="testApi" class="btn btn-success">Test Api</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: this.$route.query,
      connected: false,
      tested: false,
    };
  },
  methods: {
    sendRedirectInfo() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/xero/redirect", this.query)
        .then(({ data }) => {
          console.log(data);
          this.connected = true;
        });
    },
    testApi() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/xero/test", this.query)
        .then(({ data }) => {
          console.log(data);
          this.tested = true;
        });
    },
  },
  mounted() {
    this.sendRedirectInfo();
  },
};
</script>

<style></style>
